<template>
  <div>
    <commonDetail></commonDetail>
  </div>
</template>

<script>
import commonDetail from '../test/components/commonDetail.vue'
export default {
  components: {
    commonDetail,
  },
  data() {
    return {}
  },
  created() {},
  methods: {},
}
</script>

<style scoped></style>
